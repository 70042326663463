// 1、创建中文语言包对象
export default {
  table: {
    username: "用户名",
    email: "邮箱",
    mobile: "手机",
  },
  tank: {
    mainTitle: "全服完成挑战赛场次达到888次的所有车长获得史诗称号",
    subTitle: "当前全服达到场次",
    personTitle: "达成挑战赛场次",
    personCI: "次",
    personBattleNum: "个人战斗场次",
    personBattleNumUnit: "场",
    beforeHonour: "领先全服前",
    afterHonour: "车长",
    mainBtnOne: "未达成",
    mainBtnTwo: "领取奖励",
    mainBtnThree: "已领取奖励",
    propBtnOne: "已领取",
    propBtnTwo: "领取",
    propBtnThree: "未解锁",
    subBtn: "一键领取",
  },
};
