import axios from "axios";
//application/x-www-form-urlencoded
// axios.defaults.headers['Content-Type'] = 'application/json'
const service = axios.create({
  baseURL: process.env.NODE_ENV == "development" ? "/api" : window.baseUrl,
  withCredentials: true,
  timeout: 3000, //请求超时
});
service.interceptors.request.use(
  (config) => {
    // config.headers['token'] = 'tank-zdl020'
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.request.use(
  (config) => {
    return config;
    // config.headers['Authorization'] = 'Bearer ' + getToken()
  },
  (error) => {
    return Promise.reject(error);
  }
);
// service.interceptors.response.use(res => {
//   // return res.data
// });
export default service;
