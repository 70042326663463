<template>
  <div id="app">
    <layoutCom></layoutCom>
  </div>
</template>
<script>
import layoutCom from "./views/layout";
export default {
  components: { layoutCom },
  data() {
    return {};
  },
  created(){
    this.init()
  },
  mounted() {
    window.onresize = () => {
     this.$nextTick(()=>{
      this.init()
     })
    };
  },
  methods: {
    init(){
       let Mobile = this.isMobile();
        if (Mobile) {
          //移动
          location.href = window.otherUrl
        }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
