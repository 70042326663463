<template>
  <div class="layoutWrapper" @click="autpplays">
    <HeadCom ref="HeadCom" />
    <!-- <transition
      name="fade"
      :duration="{ enter: 500, leave: 500 }"
      mode="out-in"
    >
      <router-view class="main" />
     
    </transition> -->
    <div class="main">
      <template v-if='nowname.name=="newsDetail"'>
       <newsDetail  @upname="upname" :params="params"></newsDetail>
      </template>
       <template v-else>
         <el-carousel
        ref="carousel"
        height="100%"
        indicator-position="none"
        direction="vertical"
        :autoplay="false"
      >
        <el-carousel-item>
          <home @plays="plays"></home>
        </el-carousel-item>
        <el-carousel-item>
          <playMethon  @plays="plays"></playMethon>
        </el-carousel-item>
        <el-carousel-item>
          <battlefield></battlefield>
        </el-carousel-item>
        <el-carousel-item>
          <screenshot></screenshot>
        </el-carousel-item>
        <el-carousel-item>
          <news @upname="upname"></news>
        </el-carousel-item>
        <el-carousel-item>
          <checkout></checkout>
        </el-carousel-item>
      </el-carousel>
      </template>
     
    </div>
    <FooterCom @upname="upname" :nowname="nowname" />
     <videoModel v-if="videoModelshow" :videoSrc="videoSrc" :videoModelshow.sync='videoModelshow'></videoModel>
  </div>
</template>

<script>
import videoModel from '../components/videoModel.vue'
import HeadCom from "./headCom.vue";
import FooterCom from "./footerCom.vue";
import home from "../home/index.vue";
import playMethon from "../playMethon/index.vue";
import battlefield from "../battlefield/index.vue";
import screenshot from "../screenshot/index.vue";
import news from "../news/index.vue";
import newsDetail from "../news/newsDetail.vue";
import checkout from "../checkout/index.vue";
import { routes } from "../../router/index.js";
export default {
  name: "",
  components: {
    HeadCom,
    FooterCom,
    home,
    playMethon,
    battlefield,
    screenshot,
    news,
    checkout,
    newsDetail,
    videoModel
  },
  data() {
    return {
      routerList: routes,
      routerIndex: 0,
      nowname: {
        name: "home",
      },
      times: null,
      params:{},
      isauto:false,
      videoModelshow:false,
      videoSrc:''
    };
  },
  watch: {
    // $route(to) {
    //   this.routerList.forEach((item, index) => {
    //     if (item.name == this.$route.name) {
    //       this.routerIndex = index;
    //     }
    //   });
    //   if (to.name == "newsDetail") {
    //     window.removeEventListener("mousewheel", this.handleWheel);
    //   } else {
    //     window.addEventListener("mousewheel", this.handleWheel);
    //   }
    // },
    routerIndex() {
      this.nowname = this.routerList[this.routerIndex];
     if(this.nowname.name!='newsDetail'){
        this.$refs.carousel.setActiveItem(this.routerIndex);
     }

      
    },
  },
  mounted() {
    // this.routerList.forEach((item, index) => {
    //   if (item.name == this.$route.name) {
    //     this.routerIndex = index;
    //   }
    // });
    window.addEventListener("mousewheel", this.handleWheel);
  },
  methods: {
    plays(videoSrc){
      this.videoModelshow=true
      if(videoSrc){
        this.videoSrc=videoSrc
      }
      if(this.$refs.HeadCom.isplay){
 this.$refs.HeadCom.plays()
      }
    },
  autpplays(){
    if(!this.isauto){
      this.isauto=true
      this.$refs.HeadCom.plays()
    }
  },
    upname(val,params={}) {
      this.nowname = val;
      this.params=params
      //setActiveItem
      //routerIndex
      // if(val.name=='newsDetail'){
      //   return false
      // }
      this.$nextTick(()=>{
         let idx = this.routerList.findIndex((item) => {
        return item.name == this.nowname.name;
      });
      this.routerIndex = idx;
      })
     
    },
    handleWheel(e) {
      if(this.nowname.name=='newsDetail'){
        return false
      }
      if (e.deltaY > 0) {
        if (this.routerIndex < this.routerList.length - 2) {
          //  function newxt=()=>{
          //    this.routerIndex++;
          //  }
          clearTimeout(this.times);
          this.times = null;
          this.times = setTimeout(() => {
            this.routerIndex++;
          }, 200);
          // if (this.routerList[this.routerIndex].meta.isShow) {
          //   this.$router.push({ name: this.routerList[this.routerIndex].name });
          // }
        }
      } else if (e.deltaY < 0) {
        if (this.routerIndex > 0) {
          clearTimeout(this.times);
          this.times = null;
          this.times = setTimeout(() => {
            this.routerIndex--;
          }, 200);
          // if (this.routerList[this.routerIndex].meta.isShow) {
          //   this.$router.push({ name: this.routerList[this.routerIndex].name });
          // }
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("mousewheel", this.handleWheel);
  },
};
</script>

<style scoped lang="scss">
.layoutWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .headComWrapper {
    height: 60px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .footerComWrapper {
    height: 89px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 200;
  }
  .main {
    flex: 1;
    overflow: auto;
    background: url(../../assets/pageBg.png) no-repeat;
    background-size: 100% 100%;
  }

  .fade-leave-active {
    transition: all 0.5s linear;
  }
  /* 进入的初始状态和离开的结束状态 */
  .fade-enter,
  .fade-leave-to {
    // animation: hide-gradient 0.5s linear;
  }
  /* 进入的结束状态和离开的初始状态 */
  .fade-enter-to,
  .fade-leave {
    animation: wrapper-gradient 0.5s linear;
  }
  @keyframes wrapper-gradient {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes hide-gradient {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
::v-deep .el-carousel--vertical,
::v-deep .el-carousel__container {
  height: 100%;
}
</style>
