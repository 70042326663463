<template>
  <div class="checkoutWrapper">
    <!-- <div class="content"></div> -->
    <img class="content" src="../../assets/homeImg/FAQ/card_focus.png" alt="">
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      questionList: [
        {
          title: "1. 订单提交后，多久可以收到商品？",
          content:
            "订单提交后，一般会在1-3个工作日内发货，发货后，一般会在1-3个工作日内送达。",
        },
        {
          title: "2. 订单提交后，多久可以收到商品？",
          content:
            "订单提交后，一般会在1-3个工作日内发货，发货后，一般会在1-3个工作日内送达。",
        },
        {
          title: "3. 订单提交后，多久可以收到商品？",
          content:
            "订单提交后，一般会在1-3个工作日内发货，发货后，一般会在1-3个工作日内送达。",
        },
        {
          title: "4. 订单提交后，多久可以收到商品？",
          content:
            "订单提交后，一般会在1-3个工作日内发货，发货后，一般会在1-3个工作日内送达。",
        },
        {
          title: "5. 订单提交后，多久可以收到商品？",
          content:
            "订单提交后，一般会在1-3个工作日内发货，发货后，一般会在1-3个工作日内送达。",
        },
      ],
      i: 0,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.checkoutWrapper {
  height: 100%;
  background: url("../../assets/homeImg/FAQ/背景.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
   height:60vh;
    // height: 59.4%;
    // // border: 1px solid red;
    // background: url("../../assets/homeImg/FAQ/card_focus.png") no-repeat;
    // background-size: 100% 100%;
  }
}
</style>
