// 2、创建英文语言包对象
export default {
  table: {
    username: "Username",
    email: "Email",
    mobile: "Mobile",
  },
  tank: {
    mainTitle:
      "All captains who have completed 888 challenges across the entire server live an epic title",
    subTitle: "The current server has reached the number of sessions",
    personTitle: "Achieve challenge sessions",
    personCI: "second",
    personBattleNum: "Individual combat sessions",
    personBattleNumUnit: "site",
    beforeHonour: "More than the full server",
    afterHonour: "Vehicle commander",
    mainBtnOne: "Not achieved",
    mainBtnTwo: "Claim rewards",
    mainBtnThree: "Rewards have been claimed",
    propBtnOne: "Received",
    propBtnTwo: "receive",
    propBtnThree: "Not unlocked",
    subBtn: "One click claim",
  },
};
