<template>
  <div class="homeWrapper animate__animated animate__fadeInUpBig">
    <img class="imgs" src="../../assets//homeImg/首页_文字.png" alt="">
    <div class="bf" @click.stop="plays"></div>
    <div class="btn">
      <div @click="downloads" class="item btn1">
        <img  class="icon" src="../../assets/homeImg/steam.png" alt="">
       下载游戏</div>
      <!-- <div class="item btn2">报名测试</div> -->
    </div>
   
  </div>
</template>

<script>

export default {
  name: "",
  components: {},
  data() {
    return {
      videoModelshow:false
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    plays(){
      this.$emit('plays','https://tankdownload.golfpai.com/tankIndex/video/P1/cg.mp4')
    },
downloads(){
   window.open('https://store.steampowered.com/app/2902000/','_blank')
}
  },
};
</script>

<style scoped lang="scss">
.homeWrapper {
  background: url("../../assets/homeImg/首页1.jpg") no-repeat;
  background-position: 50% 65%;
  background-size: cover;
  position: relative;
  height: 100%;
  .btn {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 28%;
    height: 49px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    display:flex;
    justify-content: center;
    .item {
      width: 50%;
      height: 100%;
      margin: 0 10px;
      //border: 1px solid #fff;
      background:#fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% auto;
      color:#000;
      cursor: pointer;
      .icon {
        width: 26px;
        // height: 20px;
        margin-right: 14px;
        // background: url("../../assets/homeImg/steam.png") no-repeat;
        // background-size: 100% auto;
      }
    }
    .btn2 {
      background: #fff;
      color: #000;
    }
  }
  .imgs{
    width:64%;
       position: absolute;
    top: 44%;
    left:18%;
  }
  .bf {
    width: 485px;
    height: 485px;
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: url("../../assets/homeImg/播放按钮正常.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top:-243px;
    margin-left:-243px;
    // transform: translateX(-50%);
    box-sizing: border-box;
    &:hover {
      background: url("../../assets/homeImg/播放按钮滑过.png") no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url("../../assets/homeImg/播放按钮按下.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
