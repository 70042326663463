// 1、创建俄语语言包对象
export default {
  table: {
    username: "用户名",
    email: "邮箱",
    mobile: "手机",
  },
  tank: {
    mainTitle:
      "Все командиры автомобилей, достигшие 888 заездов, жили в эпическом стиле.",
    subTitle: "В настоящее время весь костюм готов.",
    personTitle: "Достижение вызова",
    personCI: "А",
    personBattleNum: "Индивидуальный бой",
    personBattleNumUnit: "Поле",
    beforeHonour: "Больше, чем вся одежда",
    afterHonour: "Капитан.",
    mainBtnOne: "Не достигнуто",
    mainBtnTwo: "Поощрение",
    mainBtnThree: "Получено вознаграждение",
    propBtnOne: "Получено",
    propBtnTwo: "Получать",
    propBtnThree: "Не разблокировано",
    subBtn: "Один клик для получения",
  },
};
