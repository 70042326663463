<template>
  <div class="contents">
    <div class="videoout">
      <div class="iconsout" @click.stop="close">
        <i class="el-icon-close"></i>
      </div>
      <video controls="" muted autoplay loop>
        <source :src="this.videoSrc" type="video/mp4">
        </video>
      <!-- <video-player
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      playerOptions: {
        playbackRates: [0.5, 1.0, 2.0], // 可选的播放速度
        controls:true,
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: "video/mp4", // 类型
            src: this.videoSrc, // url地址，若为后端返回，需为文件流
          },
        ],
        poster: "", // 封面地址,不设置会默认第一帧为封面
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
        //   currentTimeDisplay:true,//当前时间
        //   progressControl:false,
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  props: {
    videoSrc: {
      type: String,
      default:
        "https://tankdownload.golfpai.com/tankIndex/video/P1/cg.mp4",
    },
    videoModelshow: {},
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("update:videoModelshow", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.contents {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 444;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .videoout {
    width: 46%;
    // height: 50vh;
    position: relative;
    .iconsout {
      position: absolute;
      font-size: 24px;
      top: -10px;
      right: -30px;
      color: #10c47c;
      cursor: pointer;
    }
    video{
         width: 100%;
    //   height: 100%;
    }
    ::v-deep .video-player {
      width: 100%;
      height: 100%;
    }
  }
}
</style>