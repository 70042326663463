import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/playMethon",
    name: "playMethon",
    meta: {
      title: "玩法介绍",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/playMethon/index.vue"),
  },
  {
    path: "/battlefield",
    name: "battlefield",
    meta: {
      title: "战车展示",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/battlefield/index.vue"),
  },
  {
    path: "/screenshot",
    name: "screenshot",
    meta: {
      title: "游戏截图",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/screenshot/index.vue"),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "新闻公告",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/news/index.vue"),
  },

  {
    path: "/checkout",
    name: "checkout",
    meta: {
      title: "测试FAQ",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/checkout/index.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    meta: {
      title: "新闻详情",
      isShow: false, // 是否在面包屑中显示
    },
    component: () => import("../views/news/newsDetail.vue"),
  },
];
const router = new VueRouter({
  routes,
});

export default router;
