<template>
  <div class="newDetailWrapper">
    <div class="detailContent">
      <div class="backIcon">
        <div class="back" @click="gonav('news')"></div>
        <el-breadcrumb class="right" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item @click.native='gonav("home")'>首页</el-breadcrumb-item>
          <el-breadcrumb-item @click.native='gonav("news")'>新闻公告</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="textWrapper">
        <div class="title">{{ content.title }}</div>
        <div class="date">{{ content.date }}</div>
        <div class="line"></div>
        <div class="bottomContent">
          <div class="content">
            <div
              v-for="(item, index) in content.contentArr"
              :key="index"
              class="contentItem"
            >
              <div class="sectitle">
                {{item.title}}
              </div>
              <div v-for="(res,idx) in item.contentArr" :key="idx+'a'">
                <template v-if="res.img">
                  <img class="imhgs" :style="{width:res.wid}"  :src="handleImg(res.img)" alt="">
                </template>
                <template v-else>
                  <div class="linetitle">
                    {{res.name}}
                  </div>
                  <div class="namecont" v-for="(val,ix) in res.content" :key="ix+'b'">
                    <template v-if="val.img">
                  <img class="imgs" :style="{width:val.wid}" :src="handleImg(val.img)" alt="">
                </template>
                <template v-else>
                  <span class="thrtitle" v-if="val.title">
                      {{val.title}}
                    </span>
                    <span>{{val.name}}</span>
                </template>
                 
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newsDetail",
  props:{
    params:{}
  },
  components: {},
  data() {
    return {
      content: {
        title: "盛大试炼主播招募，开播赢万元京东卡",
        content:
          "2020年12月1日，盛大试炼主播招募，开播赢万元京东卡，快来加入我们吧！",
        date: "2020-12-10 12:00:00",
        img: "1.png",
        imgVisible: true,
        imgWidth: 300,
        imgHeight: 120,
        contentArr: [
          {
            label: "活动时间：",
            labelVisible: false,
            value: "2023-12-28 至 2024-01-01",
          },
          {
            label: "报名方式：",
            labelVisible: true,
            value: "斗鱼报名连接：主播招募(douyu.com)",
          },
          {
            label: "活动内容：",
            labelVisible: true,
            value: [
              "【车长集结令】在指定专区开播",
              "第一名：20000京东卡",
              "第二名：10000京东卡",
              "第三名：5000京东卡",
              "第四名：3000京东卡",
              "第五名：2000京东卡",
              "第六名：1000京东卡",
              "第七名：500京东卡",
              "第八名：300京东卡",
              "第九名：200京东卡",
              "第十名：1000京东卡",
            ],
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  methods: {
    gonav(name){
      this.$emit('upname',{
        name
      })
    },
    handleImg(name) {
      try {
        return require(`/public/newsImg/${name}`);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.content = this.params.item;
  },
};
</script>

<style scoped lang="scss">
.newDetailWrapper {
  width: 100%;
  height: 100%;
  padding:40px 0 90px 0;
  box-sizing: border-box;
  background-image: url("../../assets/homeImg/新闻公告/背景.jpg");
  background-size:100% 100%;
  .detailContent {
    width: 60%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    .backIcon {
      width: 100%;
      margin-top:70px;
      margin-left: 1%;
      display: flex;
      .back {
        width: 32px;
        height: 32px;
        cursor: pointer;
        background-image: url("../../assets/homeImg/新闻公告/左上角小标/zuo正常.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &:hover {
          background-image: url("../../assets/homeImg/新闻公告/左上角小标/zuo滑过.png");
        }
        &:active {
          background-image: url("../../assets/homeImg/新闻公告/左上角小标/zuo按下.png");
        }
      }
      .right {
        color: #fff;
        margin-top: 1%;
        margin-left: 2.5%;
      }
    }
    .textWrapper {
      height: calc(100% - 60px);
      .title {
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        color: #fff;
        margin-top: 2%;
      }
      .date {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        opacity: 0.5;
        margin-top: 2%;
      }
      .line {
        width: 100%;
        height: 20px;
        border-bottom: 1px solid #d4d2d2;
        opacity: 0.3;
        margin: 0 auto;
      }
      .bottomContent {
        padding:20px 0 20px;
        box-sizing: border-box;
        // border: 1px solid red;
        height: calc(100% - 110px);
        overflow: auto;
        .ImgWrapper {
          margin-top: 2%;
          margin-left: 10%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          // margin-left: 2%;
          .contentItem {
            // display: flex;
            // margin-top: 2%;
            // margin-left: 10%;
            font-size:12px;
            color:#fff;
           
          }
        }
      }
    }
  }
}
::v-deep .el-breadcrumb__inner {
  font-size: 16px;
  font-weight: 400;
  color: #fff !important;
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-size: 16px;
  font-weight: 400;
  color: #fff !important;
}
::v-deep .el-breadcrumb__item{
  cursor:pointer;
}
::v-deep .el-breadcrumb__inner:hover{
  color:#ffefcf!important;
}
.sectitle{
font-size:15px;
font-weight: 600;
margin-bottom:16px;
color:#ffefcf;
}
.linetitle{
  font-size:14px;
font-weight: 600;
margin-bottom:16px;
}
.thrtitle{
font-size:13px;
font-weight: 600;
margin-bottom:16px;
}
.imhgs{
  margin-bottom:16px;
}
.namecont{
  line-height:20px;
  margin-bottom:10px;
}
</style>
