<template>
  <div
    class="playMethonWrapper animate__animated animate__fadeInUpBig"
    :style="{
      backgroundImage: `url(${this.SLTArr[this.i].bigUrl})`,
      backgroundRepeat:'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }"
  >
   <img class="imgs" :src="this.SLTArr[this.i].keysrc" alt="">
    <div class="bf"  @click.stop="plays"></div>
    <!-- <div class="btn">
      <div class="item btn2">了解详细</div>
    </div> -->
    <div class="SLT">
      <div class="leftFlag"></div>
      <ul class="SLTWrapper">
        <li
          v-for="(item, index) in SLTArr"
          :key="item.bigUrl"
          class="SLTitem"
          @click="i = index"
        >
          <span :class="{ activeIcon: index === i }"></span>
          <img :src="srcObj(index)" alt="" />
        </li>
      </ul>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
       videoModelshow:false,
      i: 0,
      SLTArr: [
        {
          bigUrl: require("../../assets/homeImg/玩法介绍/a背景.jpg"),
          smallUrl: require("../../assets/homeImg/玩法介绍/a_-正常.png"),
          activeUrl: require("../../assets/homeImg/玩法介绍/a_ 选中.png"),
          keysrc:require("../../assets/homeImg/玩法介绍/坦克激战策略相容.png"),
          videoSrc:'https://tankdownload.golfpai.com/tankIndex/video/P2/video1.mp4'
        },
         {
          bigUrl: require("../../assets/homeImg/玩法介绍/c背景.jpg"),
          smallUrl: require("../../assets/homeImg/玩法介绍/c_-正常.png"),
          activeUrl: require("../../assets/homeImg/玩法介绍/c_ 选中.png"),
           keysrc:require("../../assets/homeImg/玩法介绍/艺术与战术.png"),
           videoSrc:'https://tankdownload.golfpai.com/tankIndex/video/P2/video3.mp4'
        },
        {
          bigUrl: require("../../assets/homeImg/玩法介绍/b背景.jpg"),
          smallUrl: require("../../assets/homeImg/玩法介绍/b_-正常.png"),
          activeUrl: require("../../assets/homeImg/玩法介绍/b_ 选中.png"),
          keysrc:require("../../assets/homeImg/玩法介绍/升级武装掌控战局.png"),
          videoSrc:'https://tankdownload.golfpai.com/tankIndex/video/P2/video2.mp4'
        },
      ],
    };
  },
  computed: {
    bgComObj() {
      return {
        background: `url(${this.SLTArr[this.i].bigUrl}) no-repeat`,
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
      };
    },
    srcObj() {
      return (index) => {
        return this.i === index
          ? this.SLTArr[index].activeUrl
          : this.SLTArr[index].smallUrl;
      };
    },
  },
  methods: {
     plays(){
       this.$emit('plays',this.SLTArr[this.i].videoSrc)
    }
  },
};
</script>

<style scoped lang="scss">
.playMethonWrapper {
  height: 100%;
  position: relative;
  .imgs{
    width:30%;
       position: absolute;
    bottom: 200px;
    left:35%;
  }
  .btn {
    width: 247px;
    height: 49px;
    line-height: 49px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .btn2 {
      background: #fff;
      color: #000;
    }
  }
  .bf {
    width: 485px;
    height: 485px;
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: url("../../assets/homeImg/播放按钮正常.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top:-243px;
    margin-left:-243px;
    box-sizing: border-box;
    &:hover {
      background: url("../../assets/homeImg/播放按钮滑过.png") no-repeat;
      background-size: 100% 100%;
    }
    &:active {
      background: url("../../assets/homeImg/播放按钮按下.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .SLT {
    width: 300px;
    height: 534px;
    position: absolute;
    left: 34px;
    top: 50%;
    transform: translateY(-50%);
    // border: 1px solid #fff;
    display: flex;
    .leftFlag {
      height: 100%;
      width: 2px;
      margin-left: 2px;
      // border: 1px solid red;
      background: url("../../assets/homeImg/玩法介绍/左侧线段.png");
      background-size: 100% 100%;
      // opacity: 0.2;
    }
    .SLTWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 34px;
      li {
        width: 260px;
        height: 146px;
        // border: 1px solid #fff;
        margin: 0 auto;
        margin-bottom: 20px;
        position: relative;
        cursor: pointer;
        .activeIcon {
          display: inline-block;
          width: 30px;
          height: 12px;
          // border: 1px solid #fff;
          background: url("../../assets/homeImg/玩法介绍/选中标签.png")
            no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: -14%;
          top: 50%;
          transform: translateY(-50%);
          // z-index: 100;
          // display: none;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
