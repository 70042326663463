<template>
  <div class="battlefieldWrapper"
  :class="{spileclass:i==4}"
  >
    <div class="SLT">
      <div class="leftFlag"></div>
      <ul class="SLTWrapper">
        <li
        :style="{height:(60/List.length)+'vh'}"
          v-for="(item, index) in List"
          :key="item.bigUrl"
          class="SLTitem"
          @click="i = index"
          @mouseenter="i = index"
        >
          <span :class="{ activeIcon: index === i }"></span>
          <img :src="item.smallurl" alt="" />
        </li>
      </ul>
    </div>
    <img class="imgs" v-if="i!=4" :src="List[i].bigUrl" alt="">
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      i: 0,
      List: [
        {
          name: "T72_BM",
          smallurl: require("../../assets/homeImg/战车展示/ax.png"),
          sub: "战士，肉盾",
          content: "T72_BM坦克扮演团队",
          bigUrl: require("../../assets/homeImg/战车展示/akey.png"),
        },
        {
          name: "T72_BM",
          smallurl: require("../../assets/homeImg/战车展示/bx.png"),
          sub: "战士，肉盾",
          content: "T72_BM坦克扮演团队",
          bigUrl: require("../../assets/homeImg/战车展示/bkey.png"),
        },
         {
          name: "T72_BM",
          smallurl: require("../../assets/homeImg/战车展示/cx.png"),
          sub: "战士，肉盾",
          content: "T72_BM坦克扮演团队",
          bigUrl: require("../../assets/homeImg/战车展示/ckey.png"),
        },
         {
          name: "T72_BM",
          smallurl: require("../../assets/homeImg/战车展示/dx.png"),
          sub: "战士，肉盾",
          content: "T72_BM坦克扮演团队",
          bigUrl: require("../../assets/homeImg/战车展示/dkey.png"),
        },
         {
          name: "T72_BM",
          smallurl: require("../../assets/homeImg/战车展示/更多正常.png"),
          sub: "战士，肉盾",
          content: "T72_BM坦克扮演团队",
          bigUrl: require("../../assets/homeImg/战车展示/c.jpg"),
        },
      ],
    };
  },
  computed: {
    bgComObj() {
      return {
        background: `url(${this.List[this.i].bigUrl}) no-repeat`,
        backgroundSize:'cover'
      };
    },
    // srcObj() {
    //   return (index) => {
    //     return this.i === index
    //       ? this.List[index].smallurl
    //       : this.List[index].smallUrl;
    //   };
    // },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.battlefieldWrapper {
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
   background: url("../../assets/homeImg/战车展示/大背景.jpg");
      background-size: cover;
  .imgs{
    width:60%;
    // height:90vh;
  }
  .SLT {
    // width: 300px;
    height:60vh;
    position: absolute;
    overflow:auto;
    left: 34px;
    top: 20vh;
    // transform: translateY(-50%);
    // border: 1px solid #fff;
    
    display: flex;
    .leftFlag {
      height: 100%;
      width: 2px;
      margin-left: 2px;
      margin-top:-20px;
      // border: 1px solid red;
      background: url("../../assets/homeImg/玩法介绍/左侧线段.png");
      background-size: 100% 100%;
      // opacity: 0.2;
    }
    .SLTWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 34px;
      li {
        // width: 260px;
        height:20vh;
        // border: 1px solid #fff;
        margin: 0 auto;
        padding-bottom: 20px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        .activeIcon {
          display: inline-block;
          width: 14%;
          height: 12px;
          // border: 1px solid #fff;
          background: url("../../assets/homeImg/玩法介绍/选中标签.png")
            no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: -14%;
          top: 50%;
          margin-top:-16px;
          // transform: translateY(-50%);
          // z-index: 100;
          // display: none;
        }
        img {
          // width: 100%;
          height: 100%;
          background: #000;
        }
      }
    }
  }
}
.spileclass{
   background: url("../../assets/homeImg/战车展示/c.jpg");
      background-size: cover;
}
</style>
