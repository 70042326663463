<template>
  <div class="newsWrapper">
    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" @click="goToItem(index, item)" v-for="(item, index) in List" :key="index">
          <!--             @mouseover="handleHover(index, item)"
            @mouseleave="handleLeave(index, item)" -->
          <img
            :src="handleImgUrl(item.defaultUrl)"
            
          />
          <div class="texts">
          <span class="titles">  {{item.title}}</span>
           {{item.date}}
          </div>
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev swipetBtn"></div>
      <div class="swiper-button-next swipetBtn"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";

export default {
  name: "",
  components: {},
  data() {
    return {
      List: [],
    };
  },
  computed: {},
  created() {},
  async mounted() {
    let { data } = await axios.get("/news.json");
    this.List = Object.values(data);
    this.$nextTick(() => {
      new Swiper(".swiper", {
        // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 3,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    });
  },
  methods: {
    // handleHover(index, item) {
    //   console.log(index, item);
    // item.defaultUrl = item.hoverUrl;
    // },
    // handleLeave(index, item) {
    //   console.log(index, item);
    // item.defaultUrl = item.url;
    // },
    handleImgUrl(name) {
      try {
        return `/newsImg/${name}`;
      } catch (error) {
        console.log(error, "图片");
      }
    },
    goToItem(index, item) {
      let params={
        id: index,
        item: item,
      }
      this.$emit('upname',{
        name:'newsDetail'
      },params)
      // this.$router.push({
      //   name: "newsDetail",
      //   params: {
      //     id: index,
      //     item: item,
      //   },
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.newsWrapper {
  background: url("../../assets/homeImg/新闻公告/背景.jpg") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  position: relative;
  display:flex;
  align-items: center;
  .swiper {
    width: 50%;
    // height: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    // border: 1px solid red;
    .swiper-slide {
      width: auto;
      // width: 15%;
      height: 50%;
      // border: 1px solid #fff;
      // margin-top: 20%;
      padding: 20px;
      cursor: pointer;
      &:hover {
        animation: up_animate1 1s ease forwards;
        // transform: translateY(-30px);
      }
      .texts{
        width:80%;
        height:60px;
        position:absolute;
        top:50%;
        left:50%;
        margin-left:-40%;
        margin-top:-30px;
        font-size:14px;
        color:#fff;
        display:flex;
        flex-direction: column;
        align-items: center;
        .titles{
          font-weight: 600;
          font-size:20px;
          margin-bottom:10px;
          line-height:30px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      @keyframes up_animate1 {
        0% {
          opacity: 0;
          transform: translateY(0);
        }
        100% {
          opacity: 1;
          transform: translateY(-30px);
        }
      }
    }
  }
  .swipetBtn {
    width: 64px;
    height: 64px;
    &::after {
      color: transparent;
    }
  }
  .swiper-button-prev {
    position: absolute;
    left: 16%;
    background: url("../../assets/homeImg/游戏截图/zuo正常.png") no-repeat
      center center;
    background-size: 100% 100%;
    &:hover {
      background: url("../../assets/homeImg/游戏截图/zuo滑过.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
    &:active {
      background: url("../../assets/homeImg/游戏截图/zuo按下.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
  }
  .swiper-button-next {
    position: absolute;
    right: 16%;
    background: url("../../assets/homeImg/游戏截图/you正常.png") no-repeat
      center center;
    background-size: 100% 100%;
    &:hover {
      background: url("../../assets/homeImg/游戏截图/you滑过.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
    &:active {
      background: url("../../assets/homeImg/游戏截图/you按下.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
  }
}
</style>
