import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";
// 引入全局css
import "./assets/scss/style.scss";
// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";

import "swiper/css/swiper.css";
import service from "./utils/request";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "animate.css";
// import 'video.js/dist/video-js.css';
// import 'vue-video-player/src/custom-theme.css';
// import  videoPlayer  from 'vue-video-player';
// Vue.use(videoPlayer);
Vue.use(ElementUI);
//引入echart
//4.x 引用方式
import echarts from "echarts";
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
// import 'lib-flexible'
import animated from "animate.css";
Vue.use(animated);

Vue.prototype.$echarts = echarts;
Vue.prototype.$http = service;
Vue.config.productionTip = false;
import "./permission";
import i18n from "./locales";
// 全局注册
Vue.component("icon", Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
