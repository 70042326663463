<template>
  <div class="footerComWrapper">
    <div class="nav">
      <template v-for="item in routerList">
        <!-- <router-link :key="item.path" :to="item.path" v-if="item.meta.isShow">
          {{ item.meta.title }}
        </router-link> -->
        <a :key="item.name" @click='chose(item)'
        :class="{active:item.name==nowname.name}"
         v-if="item.meta.isShow">
          {{ item.meta.title }}
        </a>
      </template>
    </div>
    <div class="EWM">
      <span>扫码加入官方微信群</span>
      <img :src="EWM" alt="" />
    </div>
  </div>
</template>

<script>
import { routes } from "../../router/index.js";
export default {
  name: "",
  props:{
    nowname:{

    }
  },
  components: {},
  data() {
    return {
      routerList: routes,
      iconImg: require("@/assets/homeImg/icon.png"),
      EWM: 'https://tankdownload.golfpai.com/tankIndex/screenshot/qb.JPG',
    };
  },
  computed: {},
  created() {},
  mounted() {
  
  },
  methods: {
    chose(val){
      this.$emit('upname',val)
    }
  },
};
</script>

<style scoped lang="scss">
.footerComWrapper {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #011126 0%, #000000 100%);
  opacity: 0.8;
  // background: url("../../assets/homeImg/tab_zhezhao.png") no-repeat;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  position:relative;
  .EWM {
    text-align:center;
    width: 120px;
    // height: 180px;
    // transform: translate(0px, -60px);
    position:absolute;
    right:20px;
    bottom:20px;
    font-size:12px;
    img {
      width: 100%;
      // height: calc(100% - 20px);
    }
  }
  .nav {
    width: 65%;
    height: 100%;
    // margin-left: 20%;
    // margin-right: 15%;
    display: flex;
    z-index: 120;
    justify-content: space-between;
    align-items: center;
    a {
      color: #fff;
      text-decoration: none;
      font-size: 24px;
      font-weight: bold;
      width: 140px;
      height: 89px;
      text-align: center;
      line-height: 89px;
      box-sizing: border-box;
      margin: 0 10px;
      cursor:pointer;
      &:hover {
        background: url("../../assets/homeImg/tab_hover.png");
        background-size: 100% 100%;
      }
     
    }
     .active {
        background: url("../../assets/homeImg/tab_hover.png");
        background-size: 100% 100%;
      }
  }
}
</style>
