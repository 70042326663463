<template>
  <div class="battlefieldWrapper">
    <div class="swiper1">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in List" :key="index">
          <img :src="item.url" alt="" />
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev swipetBtn"></div>
      <div class="swiper-button-next swipetBtn"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "",
  components: {},
  data() {
    return {
      List: [],
    };
  },
  computed: {},
  created() {
    for(let i=0;i<30;i++){
      this.List.push({
        // url:require(`../../assets/homeImg/游戏截图/${i+1}.png`)
        url:`https://tankdownload.golfpai.com/tankIndex/screenshot/${i+1<10?'0'+(1+i):i+1}.jpg`
      })
    }
  },
  mounted() {
    this.$nextTick(()=>{
  new Swiper(".swiper1", {
      // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    })
  
  },
  methods: {
    handleIndex(index) {
      return index + 1 < 10 ? "0" + (index + 1) : index + 1;
    },
  },
};
</script>

<style scoped lang="scss">
.battlefieldWrapper {
  height: 100%;
  background: url("../../assets/homeImg/游戏截图/背景.jpg") no-repeat center
    center;
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
  position: relative;
  display:flex;
  align-items: center;
  .swiper1 {
    width: 50vw;
    // height: 50vh;
    overflow: hidden;
    .swiper-slide {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #fff;
      width:100%!important;
      // border: 1px solid #000;
      box-sizing: border-box;
      position: relative;
      img {
        width: 100%;
        // height: 100%;
        // border: 1px solid #fff;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  .swipetBtn {
    width: 64px;
    height: 64px;
    &::after {
      color: transparent;
    }
  }
  .swiper-button-prev {
    position: absolute;
    left: 16%;
    background: url("../../assets/homeImg/游戏截图/zuo正常.png") no-repeat
      center center;
    background-size: 100% 100%;
    &:hover {
      background: url("../../assets/homeImg/游戏截图/zuo滑过.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
    &:active {
      background: url("../../assets/homeImg/游戏截图/zuo按下.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
  }
  .swiper-button-next {
    position: absolute;
    right: 16%;
    background: url("../../assets/homeImg/游戏截图/you正常.png") no-repeat
      center center;
    background-size: 100% 100%;
    &:hover {
      background: url("../../assets/homeImg/游戏截图/you滑过.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
    &:active {
      background: url("../../assets/homeImg/游戏截图/you按下.png") no-repeat
        center center;
      background-size: 100% 100%;
    }
  }
}
</style>
